import {useState, useContext} from 'react';
import {useCollection} from '../db';

import {AdminContext} from './Admin';
import {Post, Title, P, SaveButton, SaveFooter, Delete} from './components';

const PagePost = ({page, id, isNew, onNewSave, created_at, ...post}) => {
  const isAdmin = !!useContext(AdminContext);
  const [, createPost, deletePost, updatePost] = useCollection(page);
  const [editing, setEditing] = useState(false);
  const [{body, title}, setPost] = useState(post);
  const onEdit = key => e => {
    !editing && setEditing(true);
    setPost(post => ({...post, [key]: e.target.textContent}));
  };
  const onSave = async () => {
    if (isNew) {
      await createPost({body, title, created_at: new Date()});
    } else {
      await updatePost(id, {body, title});
    }
    setEditing(false);
    onNewSave();
  };
  const onDelete = () => {
    if (isNew) {
      onNewSave();
    } else {
      deletePost(id);
    }
  };
  return (
    <Post>
      {isAdmin && <Delete onClick={onDelete}>x</Delete>}
      <Title>
        <P
          contentEditable={isAdmin}
          onKeyUp={onEdit('title')}
        >
          {post.title}
        </P>
        <P>
          {(isNew ? new Date() : created_at.toDate()).toLocaleDateString("en-US")}
        </P>
      </Title>
      <div contentEditable={isAdmin} onKeyUp={onEdit('body')}>
        {post.body}
      </div>
      {editing && (
        <SaveFooter>
          <SaveButton onClick={onSave}>
            Save Post
          </SaveButton>
        </SaveFooter>
      )}
    </Post>
  );
};

export default PagePost;