import styled from 'styled-components';

export const Post = styled.div`
  border: 1px solid #00000085;
  background: #ffffff7a;
  padding: 1rem;
  margin-bottom: 1rem;
`;

export const Title = styled.h3`
  margin-top: 0;
  display: flex;
  justify-content: space-between;
`;

export const P = styled.p`
  margin: 0;
`;

export const SaveButton = styled.button`
  right: 0;
`;

export const SaveFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Delete = styled.div`
  cursor: pointer;
  float: right;
  padding-left: 1rem;
  &:hover {
    text-decoration: underline;
  }
`;

export const Add = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;