import {useState, useContext, useEffect} from 'react';

import {useCollection} from '../db';

import {AdminContext, SignOutButton} from './Admin';
import {Add} from './components';
import PagePost from './Post';

const Page = ({page}) => {
  const isAdmin = !!useContext(AdminContext);
  const collection = useCollection(page);
  const [posts, setPosts] = useState(collection[0]);
  console.log(posts, page);
  useEffect(() => {
    setPosts(collection[0]);
  }, [JSON.stringify(collection[0])]);
  const addPost = () => {
    // only one new post
    if (posts.some(({isNew}) => isNew)) return;
    setPosts(posts => [{
      title: 'title...',
      body: 'body...',
      created_at: {toDate() {return new Date()}},
      isNew: true
    }, ...posts]);
  };

  const removeNewPost = () => {
    setPosts(posts => posts.filter(({isNew}) => !isNew));
  };

  return (
    <div>
      {isAdmin &&
        <Add>
          <button onClick={addPost}>New Post</button>
        </Add>
      }
      {isAdmin && <SignOutButton/>}
      {posts.sort((a, b) => b.created_at.toDate() - a.created_at.toDate()).map((post, i) =>
        <PagePost
          {...post}
          key={post.id || `new-${i}`}
          page={page}
          onNewSave={removeNewPost}
        />
      )}
    </div>
  );
}

export default Page;