import {useEffect} from 'react';
import {Switch, Route, BrowserRouter as Router} from 'react-router-dom';

import './App.css';

import Header from './components/Header';
import Page from './components/Page';
import Admin, {WithAdmins} from './components/Admin';

const initCanvasTimeFilter = () => {
  if (new Date().getHours() > 20) {
    const sheet = document.styleSheets[0];
    sheet.insertRule('canvas { filter: invert(1) }', sheet.cssRules.length);
  }
};

function App() {
  useEffect(initCanvasTimeFilter, [])
  return (
    <Router>
      <div className="body">
        <Header />
        <div className="page">
          <WithAdmins>
            <Switch>
              <Route exact path={['/', '/home']}>
                <Page page='home' />
              </Route>
              <Route exact path="/work">
                <Page page='work' />
              </Route>
              <Route exact path="/blog">
                <Page page='blog' />
              </Route>
              <Route exact path="/admin">
                <Admin/>
              </Route>
            </Switch>
          </WithAdmins>
        </div>
      </div>
    </Router>
  );
}

export default App;
