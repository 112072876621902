import React, {useState, useEffect, useContext} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {useHistory} from 'react-router-dom';

import './admin.css';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
  }
};

export const AdminContext = React.createContext(null);

export const WithAdmins = ({admins, children}) => {
  const [admin, setAdmin] = useState(null);
  const history = useHistory();
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {  
      setAdmin(user);
      if (user && admins.includes(user.email) && history.location.pathname.includes('admin')) {
        history.push('/');
      }
    });
    return () => unsubscribe();
  }, [admins, history]);
  return (
    <AdminContext.Provider value={admin}>
      {children}
    </AdminContext.Provider>
  );
};

export const SignOutButton = () => {
  const admin = useContext(AdminContext);
  if (!admin) return null;
  return (
    <button className="sign-out" onClick={() => firebase.auth().signOut()}>
      Log out
    </button>
  );
}

const Admin = () => (
  <div>
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  </div>
);

export default Admin;