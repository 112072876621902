import {Link, useLocation} from 'react-router-dom';

const Header = () => {
  const {pathname} = useLocation();
  return (
    <header>
      <Link className={(pathname === '/home' || pathname === '/') ? 'active' : ''} to="/home">Home</Link>
      <Link className={pathname === '/work' ? 'active' : ''} to="/work">Work</Link>
      <Link className={pathname === '/blog' ? 'active' : ''} to="/blog">Blog</Link>
    </header>
  );
};

export default Header;